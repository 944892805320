const authors = [
    {
        name: 'Shantanu Phadke',
        imageUrl: 'shantanu.jpeg',
        bio: 'Software Engineer and Machine Learning / Artificial Intelligence Enthusiast',
        facebook: 'https://www.facebook.com/',
        twitter: 'https://www.twitter.com/',
        instagram: 'https://www.instagram.com/',
        google: 'https://www.google.com/',
        linkedin: 'https://www.linkedin.com/', 
    }
]

module.exports = authors
